import logo from './g.png';
import './App.css';

function App() {
  let url = window.location.href
  let data = url.split('/');
  console.log(data)
  let path = data[3]
  let id = data[4]
  let appUrl = "nwwapp://" +'/'+ path +'/'+ id

  var isHidden =  document.webkitHidden ||
  document.msHidden ||
  document.hidden;
  console.log(data)

  if ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i))) {
      console.log(url)
      window.location.replace(appUrl);  
  }
  else if ((navigator.userAgent.match(/android/i)) || (navigator.userAgent.match(/Android/i))) {
    window.location.replace("https://play.google.com/store/apps/details?id=com.nwwapp");
  }
  else {
      console.log('renjith browser')
      // window.location.replace("http://localhost:3000/");
  }
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} />
        <p className="App-text  ">
        Let's reimagine care delivery
        </p>
      </header>
    </div>
  );
}

export default App;
